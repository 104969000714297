<template>
  <!-- 关注公众号版块 -->
  <div class="follow">
    <h3>تەحنيكالىق قولداۋ</h3>
    <div class="info">
      <img class="icon" src="../assets/avatar.jpg" />
      <div>
        <p>ارناسوفت تەحنيكاسى-ARNASOFT</p>
        <p>ءار تۇردەگى ساباقحانالىق، كينو، اڭگىمە سيستەما جاساتپاقشى بولساڭىز قابارلاسىڭىز. ساپالى قىزمەت وتەيمىز.</p>
      </div>
      <span @click="showPopup">قابارلاسۋ</span>
    </div>
    <van-popup v-model="show">
      <div class="qrCode">
        <img src="http://mmbiz.qpic.cn/mmbiz_png/joJGlpoTg2Nop7ZpSVnA5x2k8yDgOhX6nmicK9xxkGHAAzm0AFNibEjZeJ4icIzGcibmx1nlR0F3bnoxNvFFxkJ2IA/640?wx_fmt=png&amp;from=appmsg" />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'Follow',
  data () {
    return {
      show: false
    }
  },
  methods: {
    showPopup () {
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
img {
  pointer-events: auto; /* 禁止长按图片保存 */
}
.follow {
  background-color: #fff;
  padding: 20px;

  h3 {
    text-align: right;
    direction: rtl;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .info {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 20px 10px;

    .icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-left: 14px;
      flex-shrink: 0;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      text-align: right;
      direction: rtl;
      line-height: 40px;

      p {
        &:first-of-type {
          font-size: 28px;
          color: #333;
        }
        &:last-of-type {
          font-size: 22px;
          color: #999;
        }
      }
    }

    span {
      font-size: 22px;
      color: #fff;
      background-color: #2a75ed;
      border-radius: 30px;
      padding: 10px 16px;
    }
  }

  .qrCode {
    border-radius: 10px;
    text-align: center;
    height: 390px;
    img {
      width: 380px;
      height: 380px;
    }
  }
}

/deep/.van-popup {
  border-radius: 10px;
}
</style>
